import React, { Component } from "react"
import { CometD } from "cometd"

export class Qmatic extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tickets: []
    }
  }

  componentDidMount() {
    document.body.style.cssText = "min-width: unset;"

    const urlParams = window.location.href.split("?")[1]
    const params = new URLSearchParams(urlParams)

    const baseUrl = params.get("url") // for testing : https://integration.qmatic.cloud/public-streams-cors
    const branchPrefix = params.get("branchPrefix")
    const unitId = params.get("unitId")

    const cometd = new CometD()
    cometd.unregisterTransport("websocket")

    cometd.configure({
      url: `${baseUrl}/cometd/`
    })

    cometd.addListener("/meta/connect", function (msg) {
      if (msg.successful) {
        console.log("connected")
      } else {
        console.log("disconnected")
      }
    })

    // Handshake with the server.
    cometd.handshake(reply => {
      if (reply.successful) {
        let channel = `/publicstreams/**`

        if (branchPrefix && unitId) {
          channel = `/publicstreams/display/${branchPrefix}/${unitId}`
        }

        // Subscribe to receive messages from the server.
        cometd.subscribe(channel, message => {
          const dataFromServer = message.data

          if (dataFromServer.eventType === "RESET") {
            this.setState({
              tickets: []
            })
          } else if (dataFromServer.eventType === "UPDATE") {
            const ticketNumber = dataFromServer?.eventData?.ticket
            const servicePointName = dataFromServer?.eventData?.servicePointName

            const existingTicketIndex = this.state.tickets.findIndex(ticket => ticket.ticketNumber === ticketNumber)

            if (existingTicketIndex >= 0) {
              // Update existing ticket
              this.setState(prevState => {
                const updatedTickets = [...prevState.tickets]
                updatedTickets[existingTicketIndex] = {
                  ...updatedTickets[existingTicketIndex],
                  winkTime: new Date(Date.now() + 10000)
                }
                return { tickets: updatedTickets }
              })

              // Play sound when ticket is updated
              // const audio = new Audio('/notification.mp3')
              // audio.play()
            } else {
              // Add new ticket
              const newTicket = {
                ticketNumber,
                servicePointName,
                winkTime: new Date(Date.now() + 10000),
                callTime: new Date()
              }

              this.setState(prevState => ({
                tickets: [newTicket, ...prevState.tickets]
              }))
            }
          }
        })
      }
    })

    // Set up interval to check and clean old tickets
    this.cleanupInterval = setInterval(() => {
      const oneMinuteAgo = new Date(Date.now() - 60000) // 1 minute ago

      this.setState(prevState => ({
        tickets: prevState.tickets.filter(ticket => ticket.callTime > oneMinuteAgo)
      }))
    }, 30000) // Run every 30 seconds
  }

  componentWillUnmount() {
    clearInterval(this.cleanupInterval)
  }

  render() {
    const sortedTickets = [...this.state.tickets].sort((a, b) => b.callTime.getTime() - a.callTime.getTime())

    return (
      <div style={{ padding: "20px 10px" }}>
        <div
          style={{
            fontSize: "1em",
            width: "100%",
            textAlign: "center",
            fontWeight: "bold",
            marginBottom: "10px",
            color: "#9c9c9c"
          }}
        >
          Numéros appelés{" "}
        </div>
        {sortedTickets.map(ticket => {
          const isFlashing = ticket.winkTime > new Date()

          return (
            <div
              key={`${ticket.ticketNumber}-${ticket.winkTime.getTime()}`}
              style={{
                border: "1px solid #fff",
                borderRadius: "4px",
                padding: "15px",
                marginBottom: "10px",
                animation: isFlashing ? "flash 1s ease-in-out 5" : "none",
                width: "100%",
                backgroundColor: "#30b1ff",
                color: "#fff"
              }}
            >
              <div
                style={{
                  fontSize: "2em",
                  fontWeight: "bold",
                  marginBottom: "5px",
                  textAlign: "center"
                }}
              >
                {ticket.ticketNumber}
              </div>
              <div
                style={{
                  fontSize: "1em",
                  textAlign: "center"
                }}
              >
                {ticket.servicePointName}
              </div>
            </div>
          )
        })}
        <style>
          {`
            @keyframes flash {
              0% { opacity: 1; }
              50% { opacity: 0.2; }
              100% { opacity: 1; }
            }
          `}
        </style>
      </div>
    )
  }
}
